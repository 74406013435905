@charset "UTF-8";
/* CSS Document */

$px:0.073vw;

* , :before , :after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

@mixin disc($bg:$base,$size:10px){
	width: $size;height: $size;
	border-radius: 50%;
	position: absolute;
	left: 0px;
	top: 50%;@include transform(translateY(-50%));
	background: $bg;
}

@mixin marker1($color:$base){
	color: $color;
	border-bottom: 2px dotted $color;
	font-weight: bold;
	padding-bottom: 5px;	
}

body.showBlk *,
body.showBlk *:after,
body.showBlk *:before {
	background-color: rgba(48, 210, 222, 0.13);
}


a {
	color:inherit;
	text-decoration: none;
	transition: all 0.3s linear;
}

ul  {
	list-style: none;
}

h1 , h2 , h3 , h4 , ul , ol , dl , dd , p , hr , figure {
	margin: 0;
	padding: 0;
}

header,nav,main,footer,section,aside{
	display: block;
}

strong {
	font-weight: normal;
}

form {
	margin-bottom: 0;
}

img {
	vertical-align:bottom;
}

::selection {
	color: #fff;
	background: #2C3F89;
}

::-moz-selection {
	color: #2C3F89;
	//background: #00aebb;
}

input::selection,
textarea::selection {
	color: #221814;
	background: #dbdcdc;
}

input[type=text],
input[type=tel],
input[type=email],
textarea,
button{
	-webkit-appearance: none;
	appearance: none;
	border: none;
	 
}

.collection main{
	overflow: hidden;
}

.noLink{
	text-decoration: none !important;
	cursor: default;
}

p{
	line-height: 1.6;
	//@include gothic;
	font-size: 1.4rem;
	text-align: justify;
}

.ar{
	text-align: right;
}

i{
	font-style: normal;
}

.fr{
	float: right;
}

.sp{
	display: none;
}

.idx{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
}

/* !!------------------------------------ */
/* !! 共通css */

html{
	font-size: 62.5%;
	margin: 0;
	&.font-large{
		font-size: 80%;
	}
}

body{
	margin: 0;
	//overflow-x: hidden;
	@include gothic;
	font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	//max-width: 1920px;
	background: #fff;
	&:not(.top){
		padding-top: 106px;
	}
}

p{
	font-size: 1.6rem;
	line-height: 1.66;
}

main{
	display: block;
	min-width: 1266px;

}

main > section {
	
	.inner{
		
		max-width: 1266px;
		margin: 0 auto;
		position: relative;
	}

}

ul.disc{
	list-style: disc;
	padding-left: 1.6em;
	li{
		font-size: 1.6rem;
	}
}

//!! - 見出し
h1.sec,
h2.sec{
	text-align: center;
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 1;
	i{
		@include BLW;
		font-weight: 300;
		line-height: .8;
		font-size: 1.71em;
		display: block;
		margin-bottom: 15px;
	}
}

h3.hd{
	font-size: 1.4rem;
	line-height: 1;
	font-weight: 300;
	i{
		font-size: 1.71em;
		display: block;
		margin-bottom: 10px;
		letter-spacing: .08em;
	}
}

//!! - btn
.btn-more{
	border: 1px solid #A39994;
	width: 180px;
	text-align: center;
	line-height: 38px;
	display: inline-block;
	font-size: 1.2rem;
	position: relative;
	&:after{
		content: "";
		width: 40px;height: 5px;
		position: absolute;
		top: 50%;
		left: -20px;
		@include transform(translateY(-50%));
		@include bg-img(url(../img/arr-R-s@3x.png)); //$url,$size,$position,$repeat
	}
	&.w{
		border: 1px solid #fff;
		color: #FFF;
		&:after{
			@include bg-img(url(../img/arr-R-W-s@3x.png));
		}
	}
}

.btn-s{
	width: 200px;
	line-height: 30px;
	text-align: center;
	border: 1px solid $txt;
	display: block;
	font-size: 1.2rem;
	position: relative;
	cursor: pointer;
	&:after{
		content: "";
		@include anchor($txt);//$color,$size,$Bw,$left,$right;					
	}
	&.down{
		&:after{
			content: "";
			border-width: 0 0 1px 1px !important;	
			margin-top: -1px;
			@include transform(rotate(-45deg) translateY(-75%));			
		}
	}
}

a.na{
	opacity: .2;
	pointer-events: none;
}

/* !!------------------------------------ */
/* !! ナビゲーション */

header.gnav{
	
	position: fixed;
	top: 0;left: 0;
	z-index: 401;
	background: #fff;
	width: 100%;
	height: 92px ;
	body.top & {
		@include transform(translateY(-200%));
		transition: 500ms ease-in-out;
	}
	
	body.fv_passed &{
		@include transform(translateY(0));
	}

	.inner{
		padding: 30px;
	}
	nav{
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%,-50%));
		white-space: nowrap;
		ul{
			font-size: 0;
			li{
				font-size: 14px;
				display: inline-block;
				a{
					display: block;
					line-height: 30px;
					padding: 0 15px;
					position: relative;
					&:after{
						content: "";
						height: 2px;
						width: 100%;
						background: $blue;	
						display: block;
						@include transform(scaleX(0));
						transition: 300ms $ease1;
						opacity: 0;	
					}
					&:hover,&.current{
						&:after{
							opacity: 1;
							@include transform(scaleX(1));											
						}
					}
				}
			}
		}
	}
	.fontsize{
		position: absolute;
		right: 30px;top: 50%;
		margin-top: 0;
		@include transform(translateY(-50%));
	}
	
}//header.gnav

/* !! - - -  */
/* !! gnavTop */

body.top aside.gnav{
	position: absolute;
	top: 0;left: 0;
	background: #fff;
	height: 100%;
	width: 300px;
	padding: 30px;
	
	.wrap{
		position: absolute;
		bottom: 110px;left: 30px;
		width: 240px;
	}
	
	nav ul{
		border-bottom: $line;
		line-height:1;
		padding-bottom: 20px;
		li{
			margin-bottom: 10px;
			&:last-of-type{
				margin-bottom: 0;
			}
			a{
				i{
					display: inline-block;
					background: #fff;
					padding-right: 10px;
				}
				display: block;
				line-height: 30px;
				font-size: 14px;
				@include bg-img(url(../img/arrw-R@2x.png),auto 4px,right 10px); 
			}
		}
	}
	
	.fontsize{
		line-height: 25px;
		margin-top: 30px;
	}//#fontsize

}//#gnav


.fontsize{
	line-height: 25px;
	font-size: 14px;
	margin-top: 30px;
	.toggle{
		display: inline-block;
		border: 2px solid $txt;
		margin-left: 10px;
		position: relative;
		
		&:after{
			content: "";
			width: 50%;
			display: block;
			height: 100%;
			position: absolute;
			top: 0;left: 0;
			z-index: 0;
			background: $txt;
			transition: 100ms $ease1;
			@include transform(translateX(0));
		}
		
		html.font-large &{
			&:after{
				@include transform(translateX(100%));
			}
			.rg{
				color: $txt;
			}
			.lg{
				color: #fff;
			}
		}
		
		>div{
			display: inline-block;
			position: relative;
			z-index: 1;
			width: 50px;
			text-align: center;
			line-height: 25px;
			cursor: pointer;
			font-weight: 600;
			&.rg{
				color: #fff;
			}
		}
	}
	
}//#fontsize


/* !!------------------------------------ */
/* !! トップページ */

#mv{
	padding-left: 300px;
	background: #eee;
	position: relative;
	height: 100vh;
	
	.h1-wrap{
		height: calc(100% - 180px);
		background: #000;
		position: relative;
		overflow: hidden;
		.bg{
			position: relative;
			opacity: 0;
			width:100%;
			height:100%;
			z-index: 0;
			transition: 1500ms opacity;
			.inner{
				transform-origin: center center;
				transform: scale(1.1);
				width:100%;
				transition-delay: 10ms;
				transition:1000ms ease-out transform, 3000ms ease-out filter;
				height:100%;
				display: block;
				filter:brightness(3);
				@include bg-img(url(../../img/mv1@2x.jpg),cover,center center); //$url,$size,$position,$repeat
			}
			&.on{
				opacity: 1;
				.inner{
					filter:brightness(1);
				}
			}
			body.moved_once &.on{
				.inner{
					filter : none;
					transform: scale(1);
				}
			}
			&.animating{
				will-change:transform;
				.inner{
					will-change:transform;
				}
			}
		}
		#h1txt{
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%));
			left: 50*$px;
			z-index: 1;
			span {
				font-size: 18px;
				line-height: 1.78;
				color: #ffffff;
				font-weight: 400;
				display: block;
				position: absolute;
				left: 228px;
				top: 50%;
				white-space: nowrap;
				@include transform(translateY(-50%));
				text-shadow: 0 0 4px #000;
			}
		}
	}
	
}//#mv

br.sp{
	display: none;
}

#information-wrap{
	
	position: absolute;
	bottom: 0;
	background: #EEEFEF;
	left: 300px;
	width: calc(100% - 300px);
	
	.toIndex{
		width: 80px;
		height: 100%;
		background: $txt;
		position: absolute;
		top: 0;right: 0;
		display: block;
		&:hover{
			img{
				@include transform(translate(-50%,-50%) scale(1.2));
			}
		}
		img{
			position: absolute;
			top: 50%;
			left: 50%;
			transition: 100ms $ease1;
			@include transform(translate(-50%,-50%) scale(1));
		}
		border-bottom: 1px solid rgba(255, 255, 255, 0.4);
	}
	
}//#information-wrap

.hdBox{
	position: relative;
	height: 40px;
	width: 150px;
	border: 1px solid $txt;
	i{
		@include centering;
		@include BBS;
		white-space: nowrap;
		font-weight: 500;
		font-size: 1.4rem;
		letter-spacing: .05em;
		line-height: 1;
	}
}


/* !! - - -  */
/* !! seminar-and-event */
#seminar-and-event{
	height: 100px;
	position: relative;
	padding-left: 210px;
	.hdBox{
		left: 40px;
		top: 50%;
		position: absolute;
		@include transform(translateY(-50%));
	}
	
	ul.list{
		margin: 0;
		padding: 20px 0;
		font-size: 0;
		white-space: nowrap;
		margin-right: 80px;
		li{
			display: inline-block;
			font-size: 1.4rem;
			width: calc(50% - 10px);
			vertical-align: top;
			margin-right: 20px;
			position: relative;
			padding-left: 90px;
			.img{
				width: 80px;
				height: 60px;
				position: absolute;
				top: 0;left: 0;
				background: #ccc;
				z-index: 2;
				a{
					display: block;
					height: 100%;
					position: relative;
					z-index: 1;
				}
				white-space: pre !important;
				color: #fff;
				text-align: center;
				&:before{
					content:"SEMINAR\A&\A EVENT";
					display: block;
					width: 100%;
					top: 50%;
					z-index: 1;
					@include BBS;
					line-height: 1.2;
					position: absolute;
					@include transform(translateY(-50%));
					pointer-events: none;
				}
			}
			.cat{
				background: $blue;
				color: #fff;
				font-size: 1rem;
				width: 50px;
				line-height: 20px;
				@include BBS;
				text-align: center;
				letter-spacing: .08em;
				
			}
			.ttl{
				white-space: normal;
				line-height: 1.43;
				@include pfix(.43em);
				padding-top: 5px;
			}
			
		}
	}
	
	
}//#seminar-and-event

/* !! - - -  */
/* !! blog-and-news */
#blog-and-news{

	height: 80px;
	background: #fff;
	position: relative; 
	padding-left: 210px;
	.hdBox{
		position: absolute;
		left: 40px;
		top: 20px;
	}
	
	.wrap {
		margin: 0;
		width: calc(100% - 80px) !important;
	}
	
	.swiper-wrapper{
		&:focus{outline: none;}
		height: 80px;
		width: 100%;
		padding: 10px 0;
		overflow: hidden;
		white-space: nowrap;
		overflow: hidden;
		position: relative;
		*:focus{
			outline: none !important;
		}
		.swiper-slide{
		/*
					width: calc(100% - 120px);
					height: 80px;
					p{
						position: relative;
						top: 50%;
						font-size: 1.4rem;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-right: 120px;
						@include transform(translateY(-50%));
					}
		*/
		
		
		
			display: inline-block;
			font-size: 1.4rem;
			width: calc(50% - 10px);
			vertical-align: top;
			margin-right: 20px;
			position: relative;
			padding-left: 90px;
			min-height: 70px;
			.img{
				width: 80px;
				height: 60px;
				position: absolute;
				top: 0;left: 0;
				background: #ccc;
				white-space: pre !important;
				text-align: center;
				font-size: 12px;
				color: #FFF;
				&:before{
					content:"NEWS\A&\ATOPICS";
					display: block;
					width: 100%;
					top: 50%;
					z-index: 1;
					@include BBS;
					line-height: 1.2;
					position: absolute;
					@include transform(translateY(-50%));
					pointer-events: none;
				}
				a{
					display: block;
					position: relative;
					height: 100%;
					z-index: 2;
					img{
						@include transform(none);
					}
				}
			}
			.cat{
				background: $blue;
				color: #fff;
				font-size: 1rem;
				width: 50px;
				line-height: 20px;
				@include BBS;
				text-align: center;
				letter-spacing: .08em;
				
			}
			.ttl{
				white-space: normal;
				line-height: 1.43;
				@include pfix(.43em);
				padding-top: 5px;
			}

		}
	}
	
	.slick-arrow{
		&:focus{
			outline: none;
		}
		font-size: 0;
		background-image: none;
		width: 60px;
		height: 100%;
		border-right: 1px solid #707070;
		margin: 0 !important;
		top: 0;
		position: relative;
		background: #595757;
		height: 80px;
		position: absolute;
		right: 0;
		z-index: 2;
		cursor: pointer;
		@include bg-img(url(../../img/anchor-right@2x.png),10px 16px); 
		&.slick-prev{
			right: 60px;
			@include bg-img(url(../../img/anchor-left@2x.png),10px 16px); 
		}
		& + div img{
			@include transform(translate(-50%,-50%) rotate(-180deg));
		}
		
	}
	
	
}//#blog-and-news




/* !! - - -  */
/* !! SRB TECH CONSULTING */

section#about-srbtech{
	background: #fff;
	@include bg-img(url(../../img/grade2@2x.png),100% 100%,top right); //$url,$size,$position,$repeat
	padding: 100px 0;
	position: relative;
	.img1{
		position: relative;
		z-index: 1;
	}
	.img2{
		position: absolute;
		z-index: 2;
		left: 393px;
		top: 0;
	}
	.txt{
		padding: 50px;
		position: absolute;
		bottom: -50px;right: 0;
		z-index: 3;
		background: #fff;
		h2{
			font-size: 14px;
			font-weight: normal;
			strong{
				font-size: 24px;
				display: block;
				line-height: 1;
				@include BLW;
				font-weight: 300;
				margin-bottom: 5px;
			}
		}
		p{
			font-size: 1.6rem;
			line-height: 2.25;
			padding-top: 50px;
			@include pfix(1em);
			width: 480px;
		}

	}
	
	
}//#about-srbtech



/* !! - - -  */
/* !! MAIN FEATURES */
#main-features{
	position: relative;
	@include bg-img(url(../../img/img0@2x.jpg),cover,center bottom); //$url,$size,$position,$repeat
	padding-top: 50px;
	padding-bottom: 190px;
	&:before{
		content: "";
		display: block;
		position: absolute;
		top: 0;left: 0;
		@include grade1;
		width: 100%;
		height: 100%;
		z-index: 0;
	}
	>.inner{
		//background: #ddd;
		
		max-width: 1166px;
		
		position: relative;
		z-index: 1;
	}
	.btn-more{
		background: #fff;
	}
	.row{
		max-width: 966px;
		position: relative;
		margin: 0 auto;
		margin-top: 50px;
		border-bottom: 1px solid $txt;
		padding-bottom: 50px;
		&:last-of-type{border-bottom: 0;}
		p{
			font-size: 1.6rem;
			line-height: 2.25;
			margin-top: 40px;
		}
		&._1{
			//padding-right: 50%;
			p{
				max-width: 50%;
			}
		}
		
		.stat{
			position: absolute;
			top: 0;
			right: 0;
			width: 404px;
			h4{
				text-align: center;
				strong{
					display: inline-block;
					@include side-kakko(10px,1px);
					line-height: 40px;
					font-size: 1.8rem;
					font-weight: bold;
					padding: 0 25px;
				}
			}
			.rate{
				margin-top: 25px;
				width: 405px;
				height: 200px;
				@include bg-img(url(../../img/stat-bg@3x.png)); //$url,$size,$position,$repeat
				>div{
					position: relative;
					width: 173px;
					height: 200px;
					.inner{
						@include centering;
						color: #fff;
						.num{
							font-size: 68px;
							line-height: .8;
							@include BBS;
							text-align: center;
							i{
								font-size: 36px;
							}
							&:after{
								content: "";
								width: 30px;
								height: 1px;
								display: block;
								background: #fff;
								margin: 15px auto;
							}
						}
						.txt{
							line-height: 1;
							font-size: 1.8rem;
							text-align: center;
							white-space: nowrap;
						}
					}
				}
			}
		}//.stat
	}
	
	
}//#main-features



/* !! - - -  */
/* !! SERVICE */
section#service{
	
	padding: 100px 0;
	
	.wrap{
		margin-top: 50px;
		>div{
			width: 50%;
			padding-top: 250px;
			position: relative;
			padding-bottom: 70px;
			.img{
				height: 400px;
				width: 50vw;
				background: #eee;
				position: absolute;
				top: 0;
				right: 0;
			}
			.inner{
				width: 583px;
				padding:40px;
				height: 100%;
				position: relative;
				background: #fff;
				h3{
					margin-bottom: 40px;
				}
				p{
					font-size: 16px;
					line-height: 2.25;
				}
			}
			.btn-more{
				position: absolute;
				bottom: 50px;
				right: 50px;
			}

			&._1{
				.inner{
					margin-left: 0;
				}
				.btn-more{
					right: 100px;
				}
				.img{@include bg-img(url(../../img/img2@2x.jpg),cover); }
			}
			&._2{
				.inner{
					margin-right: 0;
				}
				.img{
					@include bg-img(url(../../img/img3@2x.jpg),cover); 
					left: 0;right: auto;
				}
			}
			&._3{
				width: 100%;
				.img{
					width: 100vw;
					left: 50%;
					right: auto;
					@include bg-img(url(../../img/img4@2x.jpg),cover); 
					@include transform(translate(-50%,0));
				}
				.inner{
					width: 100%;
				}
			}
		}
	}
	
}//#service



/* !! - - -  */
/* !! CONSULTING CASE */

#consukting-case{
	padding: 70px;
	padding-bottom: 0;
	position: relative;
	&:before{
		position: absolute;
		top: 0;left: 0;
		content: "";
		width: 100%;
		height: 500px;
		z-index: 0;
		@include bg-img(url(../../img/img5@2x.jpg),cover); //$url,$size,$position,$repeat
	}
	
	.case-wrap{
		margin: 0 auto;
		margin-top: 50px;
		max-width: 1100px;
		background: rgba(255, 255, 255, 0.9);
		padding: 50px;
		>.case{

			width: 300px;
			position: relative;
			padding-bottom: 50px;
			.txt{
				position: relative;
			}
			.img{
				height: 200px;
				background: #eee;
				margin-bottom: 30px;
				img{
					width: 100% !important;
					height: auto !important;
				}
			}
			.ttl{
				font-size: 1.8rem;
				line-height: 1.5;
				@include pfix(.5em);
				padding-bottom: 10px;
				margin-bottom: 10px;
				border-bottom: $line;
			}
			.desc{
				font-size: 1.4rem;
				
			}
			.btn-more{
				position: absolute;
				bottom: 0px;
				right: 0;
			}
			

			
		}//>.case
		
	}//.case-wrap
	
}//#consukting-case



/* !! - - -  */
/* !! PERFORMANCE COMPANY */

#performance-company{
	padding: 50px 0;
	>.inner{
		max-width: 1140px;
	}
	
	.logo-list{
		margin-top: 50px;
		margin-bottom: 30px;
		border: $line;
		border-width: 0 1px;
		position: relative;
		&:before{
			content: "";
			display: block;
			height: 1px;
			width: 100vw;
			border-bottom: $line;
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%,0));
		}
		li{
			width: calc(100%/3);
			height: 150px;
			position: relative;
			border-right: $line;
			&:nth-of-type(3n){
				border-right: 0;
			}
			img{
				@include centering;
			}
		}
	}
	
	.desc{
		text-align: center;
	}
	
}//#performance-company




/* !! - - -  */
/* !! COMPANY PROFILE */

#company-profile{
	padding: 50px 0;
	padding-bottom: 100px;
	.inner{
		max-width: 100%;
		margin: 0;
		
	}
	.txt-wrap{
		width: 100%;
		margin-top: 50px;
		position: relative;
		padding: 50px 0;
		padding-left: 50%;
		@include bg-img(url(../../img/img6@2x.jpg),cover); //$url,$size,$position,$repeat
		.txt{
			padding: 50px;
			max-width: 583px;
			position: relative;
			&:before{
				content: "";
				position: absolute;
				top: 0;left: 0;
				z-index: 0;
				width:100%;
				height:100%;
				opacity: .8;
				@include bg-img(url(../../img/grade1@3x.png)); //$url,$size,$position,$repeat
			}
			p{
				color: #fff;
				position: relative;
				z-index: 1;
				line-height: 36px;
			}
		}
		.btn-more{
			margin-top: 40px;
		}
	}
	
}//#company-profile


/* !! - - -  */
/* !! page-footer */

#page-footer{
	padding: 50px 0;
	.wrap{
		max-width: 1050px;
		margin: 0 auto;
		>div{
			width: 500px;
			height: 500px;
		}
	}
	
	#FB-wrap{
		iframe{
			display: block;
			width:100%;
			height:100%;
		}
	}
	
	#post-list-wrap{
		.h2-wrap{
			height: 130px;
			border-top: 1px solid $txt;
			border-bottom: 1px dotted  $txt;
			h2{
				position: relative;
				top: 50%;
				@include transform(translateY(-50%));
			}
		}
		
		ul.post-list{
			height: 370px;overflow: auto;
			border-bottom: 1px solid $txt;
			li{
				position: relative;
				min-height: 100px;
				padding: 25px 0;
				padding-left: 118px;
				padding-right: 30px;
				border-bottom: $line;
				&:last-of-type{
					border-bottom: 0;
				}
				>a{
					@include a-cover;
				}
				.img{
					background: #efefef;
					width: 103px;
					height: 68px;
					position: absolute;
					top: 50%;left: 0;
					@include transform(translateY(-50%));
				}
				
				.txt{
					font-size: 0;
					.cat{
						background: $blue;
						color: #fff;
						font-size: 1.2rem;
						width: 50px;
						line-height: 20px;
						@include BBS;
						text-align: center;
						letter-spacing: .08em;
						display: inline-block;
					}
					.date{
						display: inline-block;
						line-height: 20px;
						margin-left: 15px;
						font-size: 1.2rem;
					}
					.ttl{
						white-space: normal;
						line-height: 1.43;
						@include pfix(.43em);
						padding-top: 5px;
						font-size: 1.4rem;
						position: relative;
						&:after{
							@include anchor($size:6px,$right:-10px,$Bw:1px);
						}
					}

				}

			}
		}
	}
	
}//#page-footer





/* !!------------------------------------ */
/* !! フッター */

.btn-toTop{
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: $base;
	position: relative;
	display: block;
	img{
		@include centering;
	}
}

#footer{
	.bnr-contact.pc{
		body.top & {
			bottom: 180*$px;
		}
		body.contact & {
			display: none !important;
		}
		bottom: 30px;
		right: 30px;
		//position: absolute;
		position: fixed;
		z-index: 6;
		transition:1000ms $ease1 right, 1000ms $ease1 bottom, 400ms $ease1 transform;
		&:hover{
			transform: scale(1.1);
		}
		body.moved & {
			bottom: 30px;
			right: 30px;
			
		}
		body.foot_inview &{
			position: absolute;
			right: 30px;
			bottom: auto;
			top: -30px;
			@include transform(translateY(-100%));
			&:hover{
				transform:translateY(-100%) scale(1.1) ;
			}
		}
	}
	position: relative;
	border-top: $line;
	.inner{
		max-width: 1266px;
		position: relative;
		margin: 0 auto;
		padding: 50px 0;
		.btn-toTop{
			position: absolute;
			right: 0;top: 50%;
			@include transform(translateY(-50%));
		}
		
	}
	
	.f-nav{
		ul{
			white-space: nowrap;
			font-size: 0;
			margin-top: 30px;
			li{
				display: inline-block;
				font-size: 1.4rem;
				&:after{
					content: "|";
					padding: 0 10px;												
				}
				&:last-of-type{
					&:after{
						content: none;
					}
				}
			}
		}
	}
	
	.sns{
		padding-left: 110px;
		position: relative;
		position: absolute;
		right: 200px;
		top: 50%;
		@include transform(translateY(-50%));
		i{
			display: block;
			position: absolute;
			left: 0;top: 50%;
			@include transform(translateY(-50%));
			line-height: .8;
			@include BBS;
			font-size: 1.4rem;
			letter-spacing: .05em;
			&:after{
				content: "";
				width: 35px;
				height: 4px;
				display: block;
				position: absolute;
				right: -5px;
				top: 3px;
				@include transform(translate(100%,0));
				@include bg-img(url(../img/arrw-R@3x.png),auto 4px,right center); //$url,$size,$position,$repeat
			}
		}
		ul{
			white-space: nowrap;
			font-size: 0;
			li{
				display: inline-block;
				margin-left: 15px;
				height: 40px;
				width: 40px;
				border-radius: 50%;
				background: $txt;
				vertical-align: top;
				a{
					display: block;
					width:100%;
					height:100%;
					position: relative;
					img{
						@include centering;
					}
				}
			}
		}
	}
}

p.copyright{
	line-height: 70px;
	text-align: center;
	font-size: 1.2rem;
	border-top: $line;
}


/* !!------------------------------------ */
/* !! 下層ページ共通 */
nav#path{
	position: relative;
	z-index: 2;
	ul{
		position: absolute;
		top: 0;left: 0;
		margin: 30px;
		line-height: 1;
		font-size: 12px;
		z-index: 1;
		li{
			display: inline-block;
			&:after{
				content: "＞";
				display: inline-block;
				margin: 0 15px;			
			}
			&:last-of-type{
				&:after{
					content: none;
										
				}
			}
		}
	}
}

h1.sec{
	margin-bottom: 50px;
}



#page-mv{
	height: calc(100vh - 106px);
	position: relative;
	padding: 0;
	&:after{
		content: "";
		width: 150vw;
		height:480px;
		@include bg-img(url(../img/grade1@2x.png),cover); //$url,$size,$position,$repeat
		position: absolute;
		top: 0;right: 0;
		z-index: 0;
		transform-origin: right top;
		@include transform(rotate(337deg));
	}
	.img{
		position: absolute;
		top: 0;right: 0;
		z-index: 1;
		width: 50%;
		height: 100%;
		background-color: #eee;
	}
	
	.txt{
		width: 50%;
		padding: 50px;
		padding-left: 100px;
		max-width: 800px;
		position: absolute;
		right: 50%;top: 50%;
		@include transform(translateY(-50%));
		margin-right: -100px;
		z-index: 2;
		h1.sec{
			text-align: left;
		}
		.inner{
			position: relative;
			z-index: 1;
		}
		.lead{
			font-size: 1.6rem;
			line-height: 2.25;
			strong{
				display: block;
				font-size: 2rem;
				font-weight: bold;
				line-height: 1.6;
				margin-bottom: 10px;
				
			}
		}
		.sign{
			text-align: right;
			margin-top: 15px;
			line-height: 2;
		}
		&:after{
			content: "";
			width:100%;
			//max-width: 120%;
			height:100%;
			position: absolute;
			top: 0;right: 0;
			background: rgba(255, 255, 255, 1);
			width: 100vw;
			z-index: 0;					
		}
	}
	.bnr-contact{
		bottom: 50*$px;
		right: 50*$px;
		position: absolute;
		z-index: 1;	
	}

}

main.lower{
	overflow: hidden;
	> section {
		.inner {
			max-width: 1000px;
			margin: 0 auto;
		}
	}
}

main.sim{
	padding-top: 50px;
}

/* !!------------------------------------ */
/* !! SRB TECHコンサルティングとは */
main#about-srbtech{
	
#page-mv{
	
	&:after{
		height: 516px;
		@include transform(rotate(349deg));				
	}
	.img{
		@include bg-img(url(../../about-srbtech/img/mv@2x.jpg),cover); //$url,$size,$position,$repeat
	}

}

/* !! - - -  */
/* !! 中国事業で成功するために必要な要素 */

section#essentials{
	padding: 100px 0;
	h2{
		font-size: 2.4rem;
		line-height: 40px;
		text-align:center;
		i{
			@include side-kakko(10px);
			display: inline-block;
			line-height: 40px;
			padding: 0 50px;
		}
		
	}
	
	.row1{
		position: relative;
		margin-top: 50px;
		padding-top: 80px;
		.img-wrap{
			position: absolute;
			top: 0;left: 0;
			width: 100%;
			z-index: 0;
		}
		.img{
			width: 50%;
			height: 400px;
			-webkit-background-size: cover !important;
			background-size: cover !important;
			&._1{
				@include bg-img(url(../../about-srbtech/img/img1@2x.jpg));
			}
			&._2{
				@include bg-img(url(../../about-srbtech/img/img2@2x.jpg));
			}
		}
		.txt1{
			max-width: 1000px;
			align-items: center;
			position: relative;
			z-index: 1;
			margin: 80px auto;
			margin-top: 0;
			.numBox{
				width: 350px;
				height: 150px;
				background: $base;
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
				color: #fff;
				font-weight: bold;
				font-size: 2rem;
				line-height: 1;
				.num{
					@include BBS;
					font-size: 6rem;
					font-weight: normal;
					margin-left: 20px;
					i{
						font-size: 0.6em;
					}
				}
			}
			.mid{
				font-size: 0;
				color: #fff;
				z-index: 1;
				strong{
					font-weight: 700;
					font-size: 3rem;
					//line-height: 50px;
				}
				img{
					display: inline-block;
					top: 50%;
					position: relative;
					margin: 0 34px;
				}
			}
			
		}
		.txt2{
			max-width: 1000px;
			margin: 0 auto;
			position: relative;
			z-index: 1;
			background: #fff;
			padding: 50px 0;
			text-align: center;
			p{
				text-align: center;
				line-height: 2.25;
				font-size: 1.6rem;
				letter-spacing: .1em;
				strong{
					@include marker1;
				}
			}
		}
		
	}//.row1
	
	.row2{
		position: relative;
		z-index: 1;
		.img{
			height: 400px;
			width: 50%;
			-webkit-background-size: cover !important;
			background-size: cover !important;
			background: #eee;
			position: relative;
			.inner{
				background: #FFF;
				width: 520px;
				height: 180px;
				position: absolute;
				z-index: 1;
				text-align: center;
				p{
					text-align: center;
					@include centering;
					white-space: nowrap;
					line-height: 1.67;
				}
			}
			&._1{
				@include bg-img(url(../../about-srbtech/img/img3@2x.jpg)); //$url,$size,$position,$repeat
				.inner{top: 72px;right: 80px;}
			}
			&._2{
				@include bg-img(url(../../about-srbtech/img/img4@2x.jpg)); //$url,$size,$position,$repeat
				.inner{top: 72px;left: 80px;}
			}
			&._3{
				@include bg-img(url(../../about-srbtech/img/img5@2x.jpg)); //$url,$size,$position,$repeat
				.inner{bottom: 72px;right: 80px;}
			}
			&._4{
				@include bg-img(url(../../about-srbtech/img/img6@2x.jpg)); //$url,$size,$position,$repeat
				.inner{bottom: 72px;left: 80px;}
			}
		}
		.summary{
			width: 800px;
			height: 150px;
			background: $base;
			color: #fff;
			position: relative;
			@include centering;
			p{
				@include centering;
				white-space: nowrap;
				line-height: 2;
				font-size: 1.6rem;
				strong{
					@include marker1(#fff);
					color: #fff !important;
				}
			}
		}
		
	}//.row2
	
}//section#essentials
	
	
}//main#about-srbtech



/* !!------------------------------------ */
/* !! 事業の特徴 */
main#features{
	
#page-mv{
	
	&:after{
		height: 516px;
		@include transform(rotate(349deg));				
	}
	.img{
		@include bg-img(url(../../features/img/mv@2x.jpg),cover); //$url,$size,$position,$repeat
	}
	z-index: 1;
}


/* !! - - -  */
/* !! 情報とネットワーク */
section#information-network{
	position: relative;
	z-index: 2;
	background: #fff;
	padding: 100px 0;
	padding-bottom: 0;
	background: $gray-lite2;
	&:after{
		content: "";
		height: 1px;
		background: $txt;	
		display: block;
		margin: 0 auto;
		max-width: 1266px;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 50%;
		@include transform(translateX(-50%));			
	}
	h2{
		text-align: center;
		
		i{
			font-weight: 600;
			font-size: 2.4rem;
		}
	}
	
	.row1{
		justify-content: center;
		margin-top: 50px;
		margin-bottom: 100px;
		
		>div{
			&._2{
				margin-left: 50px;
			}
			h3{
				max-width: 350px;
				margin: 0 auto;
				line-height: 40px;
				@include side-kakko(10px);
				text-align: center;
				font-size: 1.8rem;
			}

			.rate{
				margin: 0 auto;
				margin-top: 30px;
				width: 405px;
				height: 200px;
				@include bg-img(url(../../img/stat-bg@3x.png)); //$url,$size,$position,$repeat
				>div{
					position: relative;
					width: 173px;
					height: 200px;
					.inner{
						@include centering;
						color: #fff;
						.num{
							font-size: 68px;
							line-height: .8;
							@include BBS;
							text-align: center;
							i{
								font-size: 36px;
							}
							&:after{
								content: "";
								width: 30px;
								height: 1px;
								display: block;
								background: #fff;
								margin: 15px auto;
							}
						}
						.txt{
							line-height: 1;
							font-size: 1.8rem;
							text-align: center;
							white-space: nowrap;
						}
					}
				}
			}

			ul.idx{
				justify-content: space-between;
				flex-wrap: wrap;
				width: 633px;
				li{
					width: 300px;
					height: 85px;
					background: $base;
					color: #fff;
					align-items: center;
					justify-content: center;
					display: flex;
					text-align: center;
					margin-top: 30px;
					font-size: 1.8rem;
					font-weight: bold;
				}
			}
			p{
				white-space: nowrap;
				text-align: center;
				font-weight: bold;
				margin-top: 30px;
			}
		}
		
	}//.row1
	
	
	
	.row2{
		background: #fff;
		.img{
			width: 100%;
			height: 400px;
			@include bg-img(url(../../features/img/img1@2x.jpg),cover);
		}
		
		.idx{
			max-width: 1266px;
			margin: 0 auto;
			margin-top: -150px;
			position: relative;
			z-index: 1;
			.txt{
				background: #fff;
				width: 563px;
				padding: 50px;
				h3{
					font-size: 2.4rem;
					line-height: 1;
					margin-bottom: 20px;
				}
				p{
					line-height: 2.25em;
				}
			}
		}
		
	}//.row2
	
	
	.row3{
		padding: 100px 0;
		@include bg-img(url(../../features/img/bg@2x.jpg),cover); //$url,$size,$position,$repeat
		
		.network-map{
			position: relative;
			max-width: 1000px;
			margin: 0 auto;
			width: 1000px;
			align-items: center;
			h3{
				width: 150px;
				height: 150px;
				border-radius: 50%;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				img{
					width: 115px;
					height: auto;
					display: block;
				}
			}
				
			svg{
				width: 50px;
			}
			
			dl{
				width: 800px;
				font-size: 0;
				background: #fff;
				dt{
					font-size: 18px;
					color: #FFF;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 250px;
					height: 80px;
					background: $base;
					border-bottom: 1px solid rgba(255, 255, 255, 0.42);
				}
				dd{
					align-items: center;
					display: flex;
					width: 550px;
					font-size: 16px;
					padding-left: 30px;
					i{
						display: inline-block;
						margin-right: 1em;
					}
					&:nth-of-type(2n){
						background: $gray-lite2;
					}
				}
			}
			
		}//.network-map
	}//.row3
	
	
	.row4{
		background: #fff;
		padding-top: 50px;
		padding-bottom: 100px;
		h3{
			font-size: 2.4rem;
			line-height: 1;
			margin-bottom: 20px;
		}
		p{
			b{
				font-size: 1.8rem;
			}
			line-height: 2.25;
			
		}
		
	}//.row4
	
}//section#information-netowork


/* !! - - -  */
/* !! 戦略パートナー */
section#strategy-partner{
	
	padding: 100px 0;
	
	h2{
		i{
			font-weight: bold;
		}
	}
	
	.row1{
		margin-top: 50px;
		h3{
			font-weight: bold;
			line-height: 1;
			margin-bottom: 20px;
			font-size: 2.4rem;
		}
		p{
			line-height: 2.25;
		}
		.img{
			height: 400px;
			margin: 50px 0;
			@include bg-img(url(../../features/img/img2@2x.jpg),cover); //$url,$size,$position,$repeat
		}
	}//.row1
	
	.row2{
		
		h3{
			font-weight: bold;
			line-height: 1;
			margin-bottom: 30px;
			font-size: 2.4rem;
		}
		
		.inner >div{
			border: 1px solid $txt;
			padding: 50px;
			padding-left: 35px;
			position: relative;
			z-index: 1;
			margin-top: 30px;
			.label{
				background: $base;
				width: 70px;
				color: #fff;
				font-size: 1.8rem;
				font-weight: bold;
				padding: 20px 0;
				position: absolute;
				top: 50%;
				left: 0;
				min-height: 200px;
				
				@include transform(translate(-50%,-50%));
				p{
					position: absolute;
					@include centering;
				}
				i{
					display: block;
					width: 100%;
					margin: 0 auto;
					text-align: center;
					line-height: 1.1;
					min-height: .5em;
				}
			}
			ul{
				li{
					position: relative;
					padding-left: 250px;
					font-size: 1.6rem;
					line-height: 1.6;
					margin-bottom: 25px;
					&:last-of-type{
						margin-bottom: 0;
					}
					.img{
						width: 250px;position: absolute;
						top: 0;
						left: 0;
						
						img{
							margin: 0 auto;
							display: block;
						}
					}
					b{
						display: block;
						font-size: 2rem;
					}
				}
			}
		}
		
	}//.row2
	
}//section#strategy-partner


	
}//main#features


/* !!------------------------------------ */
/* !! サービス内容 */
main#service{
	
#page-mv{
	
	&:after{
		height: 516px;
		@include transform(rotate(349deg));			
	}
	.img{
		@include bg-img(url(../../service/img/mv@2x.jpg),cover); //$url,$size,$position,$repeat
	}

}
	
/* !! - - -  */
/* !! SRB TECHが得意とする業務分野 */

section#field{
	padding: 100px 0;
	position: relative;
	h2{
		font-size: 2.4rem;
		text-align: center;
		margin-bottom: 50px;
	}
	
	.txt{
		width: 600px;
		background: #fff;
		padding: 50px;
		h3{
			margin-bottom: 50px;
		}
		h4{
			font-size: 1.8rem;
			text-align: center;
			line-height: 40px;
			@include side-kakko(10px);
		}
		ul{
			margin-top: 25px;
			li{
				width: 50%;
				font-size: 1.5rem;
				padding-left: 25px;
				position: relative;
				margin-bottom: 15px;
				white-space: nowrap;
				&:before{
					content:"";
					width: 20px;
					height: 20px;
					position: absolute;
					top: 50%;left: 0;
					@include transform(translateY(-50%));
					@include bg-img(url(../img/anchor@2x.png)); //$url,$size,$position,$repeat
				}
				&.w2{
					width: 100%;
				}
			}
		}
	}
	
	.row1{
		padding-left: 50%;
		position: relative;
		.img{
			position: absolute;
			top: 0;
			right: 50%;
			height: 100%;
			width: 50vw;
			@include bg-img(url(../../service/img/img1@2x.jpg),cover); //$url,$size,$position,$repeat
		}
	}
	
	.row2{
		justify-content: center;
		position: relative;
		z-index: 1;
		>div{
			background: #fff;
		}
		.txt{
			height: 100%;
			z-index: 1;
			background: #fff;
			position: relative;
		}
		.img{
			position: absolute;
			background: #ccc;
			height: 100%;
			top: 0;
			z-index: 0;
			width: 50vw;
		}
		._2{
			position: relative;
			.img{
				right: 0;
			}
		}
		._3{
			position: relative;
			.img{
				left: 0;
			}
		}
	}
	
	.row3{
		margin-top: -140px;
		position: relative;
		z-index: 0;
		margin-bottom: 50px;
		.img{
			width: 50%;
			height: 400px;
			background: #eee;
			&._1{
				@include bg-img(url(../../service/img/img2@2x.jpg),cover)
			}
			&._2{
				@include bg-img(url(../../service/img/img3@2x.jpg),cover)
			}
		}
	}
	
	
	.row4{
		max-width: 1000px;
		margin: 0 auto;
		
		>div{
			width: calc(50% - 25px);
			h3{
				text-align: left;
				font-size: 2.4rem;
				font-weight: bold;
				background: #ccc;
				line-height: 60px;
				position: relative;
				margin-bottom: 30px;
				i{
					position: relative;
					z-index: 1;
					color: #FFF;
				}
				&:after{
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					width: 50vw;
					height: 100%;
					z-index: 0;
					@include bg-img(url(../../service/img/bg@2x.jpg)); //$url,$size,$position,$repeat					
				}
			}
			.img{
				width: calc(100% + 50px);
				float: right;clear: both;
				margin-bottom: 20px;
				height: 300px;
			}
			p{
				margin-top: 20px !important;
				line-height: 30px;
				strong{
					@include marker1;
				}
			}
			
			&._5{
				h3{
					text-align: right;
					&:after{
						top: 0;
						right: auto !important;
						left: 0 !important;
						width: 50vw;
						height: 100%;
						z-index: 0;
						transform: rotate(180deg);
					}
				}
				.img{
					float: left;
				}
				
			}
			
		}
	}
	
	.stage{
		text-align: right;
		font-size: 0;
		position: relative;
		>div{
			height: 100px;
			display: inline-block;
			position: relative;
			i{
				color: #fff;
				font-size: 1.8rem;
				font-weight: bold;
				text-align: left;
				position: absolute;
				left: 30px;
				top: 50%;
				@include transform(translateY(-50%));
			}
		}
		._1{
			width: 314px;
			background: #0068B6;
		}
		._2{
			background: #3B87C5;
			width: 414px;
		}
		._3{
			background: #6DA4D3;
			width: 100%;
		}
		._4,._5{
			background: rgba(255, 255, 255, 0.8);
			height: 70px;
			border-radius: 35px 0 0 35px;
			position: absolute;
			top: 15px;
			right: 0;
			width: 174px;
			i{
				white-space: nowrap;
				color: $base;
			}
			&._5{
				width: 267px;
				top: auto;
				bottom: 65px;
			}
		}
	}
		
}//section#field


/* !! - - -  */
/* !! 進出手順と弊社のご支援の例 */

section#flow{
	
	padding: 100px 0;
	background: $gray-lite2;
	
	h2{
		font-size: 2.4rem;
		line-height: 1;
		margin-bottom: 20px;
	}
	.lead{
		p{
			font-size: 1.6rem;
			line-height: 1.88;
		}
	}
	ul.flow-list{
		margin-top: 50px;
		>li{
			padding: 50px 0;
			border-top: 2px dotted $txt;
			&:last-of-type{
				border-bottom: 2px dotted $txt;
			}
			h3{
				line-height: 1;
				color: $base;
				font-size: 2rem;
				margin-bottom: 15px;
				font-weight: bold;
				position: relative;
				padding-left: 20px;
				&:before{
					content: "";
					@include disc;
				}
			}
		}
	}
	
	ul.flow{
		margin-top: 15px;
		padding-left: 70px;
		height: 100px;
		font-size: 0;
		position: relative;
		overflow: hidden;
		&:before{
			content:"";
			width: 50px;
			height: 33px;
			position: absolute;
			left: 0;
			top: 50%;
			@include transform(translateY(-50%));
		}
		&.jp:before{
			@include bg-img(url(../img/fl_jp@2x.png)); 
		}
		&.cn:before{
			@include bg-img(url(../img/fl_cn@2x.png)); 
		}
		li{
			display: inline-block;
			height: 100%;
			position: relative;
			background: #ccc;
			width: calc((100% - 55px) / 4 );
			
			&:nth-of-type(1){
				z-index: 4;
				background: #A5AECB;
				i{
					margin-left: 10px;
				}
				&:after{
					background: #A5AECB;
				}
			}
			&:nth-of-type(2){
				z-index: 3;
				background: #7D89B5;
				&:after{
					background: #7D89B5;
				}
			}
			&:nth-of-type(3){
				z-index: 2;
				background: #54649E;
				&:after{
					background: #54649E;
				}
			}
			&:nth-of-type(4){
				z-index: 1;
				background: #2B3F88;
				&:after{
					background: #2B3F88;
				}
			}
			
			&:before{
				content: "";
				width: 80px;
				height: 80px;
				box-sizing: content-box;
				z-index: 0;
				position: absolute;
				right:-7px;
				top: 50%;
				background: $gray-lite2;
				//border: 7px solid #fff;
				//border-width: 7px 7px 0 0;
				transform-origin: center;
				transform:translate(50%,-50%) rotate(45deg)  ;
			}
			&:after{
				content: "";
				width: 75px;
				height: 75px;
				box-sizing: content-box;
				z-index: 0;
				background: #fff;
				position: absolute;
				right:0px;
				top: 50%;
				//border: 7px solid #fff;
				//border-width: 7px 7px 0 0;
				transform-origin: center;
				transform:translate(50%,-50%) rotate(45deg)  ;
			}
			i{
				margin-left: 36px;
				white-space: nowrap;
				color: #fff;
				z-index: 2;
				font-size: 1.8rem;
				@include centering;
				font-weight: bold;
				text-align: center;
			}
		}
	}
	
	
	.mention{
		font-size: 2.4rem;
		text-align: center;
		line-height: 1;
		margin-top: 50px;
		color: $base;
		font-weight: bold;
	}	
}//section#flow


/* !! - - -  */
/* !! コンサルティング費用の構成 */
section#composition{
	padding: 100px 0;
	h2{
		font-size: 2.4em;
		line-height: 1;
		max-width: 1000px;
		margin: 0 auto;
		span{
			font-size: 0.75em;
			color: $base;
			line-height: 20px;
		}
	}
	
	.row-grp{
		
		>div{
			height: 400px;
			margin-top: 50px;
			padding-right: 50%;
			position: relative;
			.img{
				height: 100%;
				background: #eee;
				z-index: 0;
				-webkit-background-size: cover !important;
				background-size: cover !important;
				&._1{@include bg-img(url(../../service/img/img5@2x.jpg));}
				&._2{@include bg-img(url(../../service/img/img6@2x.jpg));}
				&._3{@include bg-img(url(../../service/img/img7@2x.jpg));}
			}
			&:nth-of-type(2n){
				padding-right: 0;
				padding-left: 50%;
				.txt{
					left: auto;
					right: 50%;
					margin-left: 0;
					margin-right: -100px;
				}
			}
			.txt{
				margin-left: -100px;
				padding: 50px;
				background: #fff;
				width: 600px;
				position: absolute;
				top: 50%;@include transform(translateY(-50%));
				left: 50%;
				z-index: 1;
				h3{
					font-size: 2rem;
					line-height: 1.4a ;
					margin-bottom: 20px;
				}
				ul{
					li{
						position: relative;
						padding-left: 10px;
						font-size: 1.6rem;
						&:before{
							content:"";
							@include disc($txt,4px);
							top: 12px !important;
						}
					}
				}
				
			}
		}
		
	}//.row-grp
	
	
	.option{
		max-width: 1000px;
		margin: 0 auto;
		margin-top: 50px;
		border: 2px solid $base;
		padding: 30px 50px;
		color: $base;
		font-size: 1.6rem;
		position: relative;
		white-space: nowrap;
		&:before{
			content:"";
			width: 80px;
			height: 80px;
			display: block;
			position: absolute;
			top: 50%;left: 0;
			@include transform(translate(-50%,-50%));
			@include bg-img(url(../../service/img/option@2x.png)); //$url,$size,$position,$repeat
		}
		h3{
			font-weight: bold;
		}
	}

	
}//section#composition

}//main#service




/* !!------------------------------------ */
/* !! コンサルティング事例 */

main#consulting{
	
#page-mv{
	
	&:after{
		height: 516px;
		@include transform(rotate(349deg));			
	}
	.img{
		@include bg-img(url(../../consulting/img/mv@2x.jpg),cover); //$url,$size,$position,$repeat
	}

}

h2{
	font-size: 2.4rem;
	text-align: center;
	margin-bottom: 50px;
}

.case-solution{
	padding: 100px 0;
	padding-bottom: 0;
	>.img{
		height: 400px;
		background: #eee;
		-webkit-background-size: cover !important;
		background-size: cover !important;
	}
	
	.inner{
		padding: 50px;
		padding-bottom: 0;
		max-width: 1100px !important;
		margin: 0 auto;
		background: #fff;
		margin-top: -150px;
	}
	
	&#licence >.img{
		@include bg-img(url(../../consulting/img/img1@2x.jpg));
	}
	
	&#store_etc >.img{
		@include bg-img(url(../../consulting/img/img4@2x.jpg));
	}

	&#disputing >.img{
		@include bg-img(url(../../consulting/img/img7@2x.jpg));
	}

}

//!! case-solution
.case-wrap{
	position: relative;
	margin-bottom: 70px;
	&:last-of-type{
		margin-bottom: 0;
	}
	>div{
		padding-left: 115px;
		position: relative;
		margin-bottom: 70px;
		
		&:last-of-type{margin-bottom: 0;}
		>i{
			width: 100px;
			height: 100px;
			background: $base;
			color: #fff;
			display: block;
			font-size: 2.4rem;
			@include BBS;
			@include flex-centering;
			border-radius: 50%;
			letter-spacing: 0;
			position: absolute;
			left: 0;
			top: -20px;
		}
		
		
		.fr{
			float: right;
		}
		.arr{
			position: absolute;
			width: 23px;
			left: 37px;
			bottom: -35px;
			height: calc(100% - 60px);
			overflow: hidden;
			img{
				position: absolute;
				bottom: 0;left: 0;
			}
		}
		&.has-logo{
			padding-right: 290px;
			img{
				position: absolute;
				top: -20px;right: 0;
			}
		}
		&.case{
			&:before{
				content:"";
				
			}
		}
		&.solution > i{
			background: $blue;
		}
		h3{
			font-size: 2.4rem;
			line-height: 1;
			font-weight: 500;
			border-bottom: 2px dotted $txt;
			padding-bottom: 15px;
			margin-bottom: 8px;
		}
		
		ul{
			li{
				font-size: 1.6rem;
				letter-spacing: 0;
				line-height: 2.25;
				strong{
					@include marker1;
					font-weight: 600;
					border-bottom-style: solid;
				}
			}
		}
		
		.idx{
			margin-top: 30px;
		}
		
		p{
			margin-top: 8px;
		}
		.note{
			@include note(1em);
			margin-top: 8px;
		}
	}//.case
}



/* !! - - -  */
/* !! 投資企業事例 */

#investiment-case{
	
	padding: 100px 0;
	h2{
		font-size: 1.4rem;
	}
	
	.display{
		max-width: 1000px;
		margin: 0 auto;
		margin-top: 50px;
		position: relative;
		h3{
			font-size: 1.8rem;
			text-align: center;
			line-height: 1;
			@include flex-centering;
			i{
				font-size: 1.6rem;
				display: block;
				margin-top: 10px;
			}
			color: #fff;
			font-size: 18px;
			position: absolute;
			top: 0;left: 0;
			width: 200px;
			height: 100%;
			@include bg-img(url(../../consulting/img/display-bg@2x.png)); //$url,$size,$position,$repeat
		}
		svg{
			position: absolute;
			left: 200px;
			top: 50%;
			@include transform(translateY(-50%));
		}
		ul{
			margin-left: 325px;
			font-size: 0;
			li{
				margin-bottom: 30px;
				color: #fff;
				font-size: 1.8rem;
				position: relative;
				text-align: center;
				&:before{
					content:"";
					width: 45px;
					height: 30px;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					@include transform(translate(-50%,-50%));
				}
				&.jp:before{
					@include bg-img(url(../img/fl_jp@2x.png)); 
				}
				&.cn:before{
					@include bg-img(url(../img/fl_cn@2x.png)); 
				}
				&:last-of-type{
					margin-bottom: 0;
				}
				i{
					background: $base;
					@include flex-centering;
					width: 350px;
					height: 100px;
					position: relative;
					z-index: 1;
				}
				span{
					position: absolute;
					width: 275px;
					display: block;
					right:0px;
					height: 100px;
					top: 0;
					font-size: 1.6rem;
					border: 1px solid $txt;
					color: $txt;
					@include flex-centering;
					b{
						font-weight: normal;
					}
					&:before{
						content:"";
						width: 60px;
						height: 1px;
						background: $txt;
						position: absolute;
						left: -60px;
						top: 49px;
						z-index: 0;
					}
				}
			}
			li.cap{
				position: absolute;
				top: 0;
				right: 0;
				width: 275px;
				height: 100px;
				color: $txt;
				@include flex-centering;
				justify-content: flex-end;
			}
		}
	}
	
}//#investiment-case
	
}//main#consulting
	

/* !!------------------------------------ */
/* !! 実績企業 */

main#performance{
	
#page-mv{
	
	&:after{
		height: 516px;
		@include transform(rotate(349deg));			
	}
	.img{
		@include bg-img(url(../../performance/img/mv@2x.jpg),cover); //$url,$size,$position,$repeat
	}

}

section.table{
	padding: 100px 0;
	table{
		max-width: 1000px;
		margin: 0 auto;
		font-size: 1.6rem;
		border: none !important;
		tr{
			position: relative;
			&:first-of-type{
				
				td{
					background: $base;
					color: #fff;
					text-align: center;
					&:first-of-type:after,
					&:last-of-type:after{
						content:"";
						background: $base;
					}
				}
			}
			&:nth-of-type(2n){
				td{
					background: #efefef;
					&:first-of-type:after,
					&:last-of-type:after{
						content:"";
					}
				}
			}
		}
		td{
			position: relative;
			&:first-of-type{
				text-align: center;
				&:after{
					content:none;
					width: 100vw;
					height: 100%;
					background: #eee;
					position: absolute;
					left:0;
					top: 0;
					@include transform(translateX(-100%));
				}				
			}
			height: 50px;
			padding: 15px;
			border-right: 1px solid #ccc;
			white-space: nowrap;
			&:last-of-type{
				border-right: 0;
				&:after{
					content:none;
					width: 100vw;
					height: 100%;
					background: #eee;
					position: absolute;
					right:0;
					top: 0;
					@include transform(translateX(100%));
				}
			}
		}
	}
	
}//section.table


}//#performance

/* !!------------------------------------ */
/* !! 会社概要 */
main#company{
	
#page-mv{
	height: auto;

	.img{
		@include bg-img(url(../../company/img/mv@2.jpg),cover); //$url,$size,$position,$repeat
	}
	
	.txt{
		width: calc( 50% + 100px ) !important;
		position: relative;
		max-width: 100vw !important;
		right: 0;
		top: 150px;
		@include transform(translateY(0));
		.inner{
			max-width: 800px;
			margin: 0 auto;
			margin-right: 0;
		}
	}
}

/* !! - - -  */
/* !! パートナー挨拶 */
section.greeting{
	margin-top: 150px; 
	padding: 100px 0;
	h2.sec{
		text-align: left;
		position: relative;
		z-index: 1;
	}
	.inner{
		background: #fff;
		position: relative;
		&:before{
			content:"";
			background: #fff;
			width: calc(100% + 100px);
			height: calc(100% + 100px);
			position: absolute;
			z-index: 0;
			left: -50px;
			top: -50px;
		}
	}
	p{
		margin: 0;
		position: relative;
		z-index: 1;
		line-height: 2.25;
	}
	
	.txt{
		margin-top: 50px;
		width: 550px;
		z-index: 1;
		position: relative;
	}
	
	.sign{
		text-align: right;
		@include pfix(1.25em);
		padding-top: 30px;
		img{
			position: relative;
			top: -5px;
		}
	}
	
	.img{
		position: absolute;
		top: 0;right: 0;
	}
	
	.manifest{
		font-size: 2.4rem;
		text-align: center;
		line-height: 1.6;
		color: $base;
		margin-top: 150px;
		strong{
			font-weight: bold;
		}
	}
	
}//section.greeting	
	
/* !! - - -  */
/* !! 会社概要 */
	
#outline{
	padding: 100px 0;
	background: $gray-lite2;
}

ul.outline{
	margin-top: 50px;
	>li{
		border-top: 1px solid $txt;
		padding: 20px 0;
		font-size: 16px;
		padding-left: 172px;
		position: relative;
		&:last-of-type{
			border-bottom: 1px solid $txt;
		}
		>i{
			position: absolute;
			line-height:1.5;
			top: 20px;left: 0;
			padding-left: 20px;
		}
		div{
			position: relative;
			.toggle{
				position: absolute;
				right: 0;
				top: -5px;
			}
		}
		
		ol{
			line-height: 1.75;
			li{
				margin-bottom: 10px;
			}
		}
		
		.idx.add{
			margin-bottom: 20px;
			p{
				&:first-of-type{
					width: 143px;
					
					margin-right: 10px;
					line-height: 30px;
					position: relative;
					&:before{
						content:"";
						width: 100%;
						height: 1px;
						position: absolute;
						top: 50%;left: 0;
						margin-top: -1px;
						border-bottom: 1px dotted $txt;
					}
					i{
						display: inline-block;
						background: $gray-lite2;
						position: relative;
						padding-right: 6px;
						z-index: 1;
						vertical-align: middle;
					}
				}
				&:nth-of-type(2n){
					flex-grow: 2;
				}
			}
		}
		
		.idx._2{
			margin-bottom: 0;
			p{
				white-space: nowrap;
				align-items: center;
				
				&:before{
					content:none!important;
					
				}
			}
			.idx.mid{
				margin-bottom: 0;
				margin-left: 50px;
				align-items: center;
				justify-content: flex-start;
				img{
					margin-right: 30px;
				}
			}
		}
		
		&.partner{
			min-height: 90px;
		}
	}
	

	
	ul.biography{
		display: none;
		padding-top: 20px;
		padding-left: 80px;
		margin-top: 20px;
		max-width: 628px;
		line-height: 1.86;
		border-top: 1px dotted $txt;
		position: relative;
		.hd{
			
			background: $base;
			width: 40px;
			height: calc(100% - 20px);
			position: absolute;
			bottom: 0;left: 0;
			list-style: none;
			i{
				text-align: center;
				width: 1em;
				color: #fff;
				@include centering;
			}
		}
	}
	
	

	
	
}//ul.outline



/* !! - - -  */
/* !! アクセス */
#access{
	
	padding: 100px 0;
	
	.map{
		margin-top: 50px !important;
		height: 300px;
		background: #eee;
		position: relative;
		iframe{
			display: block;
			width: 100%;
			height: 100%;
		}
		.txt{
			padding: 30px;
			color: #fff;
			z-index: 1;
			width: 550px;height: 200px;
			@include bg-img(url(../../company/img/bg@2x.png)); //$url,$size,$position,$repeat
			position: absolute;
			left: 50%;
			top: 50px;
			line-height: 1.75;
			b{
				font-size: 18px;
			}
			.btn-more{
				position: absolute;
				bottom: 30px;right: 30px;
			}
		}
	}
	
}//#access


/* !! - - -  */
/* !! 組織図 */

#organization{
	padding-bottom: 100px;
	.inner{
		max-width: 1000px;
	}
	.organization-map{
		margin-top: 50px;
	}
	.row{
		font-size: 0;
		margin-bottom: 30px;
		z-index: 1;
		position: relative;
		&._1,&._2{
			>div{
				@include bg-img(url(../../company/img/bg@2x.png)); //$url,$size,$position,$repeat
			}
			.base{
				background-image: none;
				background: $base !important;
				ul{
					position: absolute;
					width: 100%;
					text-align: center;
					bottom: -8px;left: 0;
					font-size: 15px;
					color: $txt;
					@include transform(translateY(100%));
				}
			}
		}
		&:last-of-type{
			margin-bottom: 0;
		}
		>div{
			vertical-align: top;
			width: calc((100% - 120px) / 5);
			height: 70px;
			background: #ccc;
			position: relative;
			color: #fff;
			display: inline-block;
			margin-right: 30px;
			i{
				@include centering;
				font-size: 16px;
				display: block;
				white-space: nowrap;
				text-align: center;
				b{
					font-size: 12px;
					display: block;
					font-weight: normal;
				}
			}
		}
		&._2{
			text-align: center;
			margin-bottom: 0;
			>div{
				margin: 0;
			}
		}
	}
	
	.line-wrap{
		width: 100%;
		position: relative;
		height: 60px;
		svg{
			position: absolute;
			bottom: 0;left: 0;
		}
	}
	
	.list-grp{
		position: relative;
		font-size: 14px;
		.side{
			position: absolute;
			width: auto;
			left: -30px;
			top: 70px;
			@include transform(translateX(-100%));
			>li{
				padding-top: 20px;
				border-bottom: 1px solid #fff;
				padding-bottom: 8px;
				line-height: 1;
				font-weight: bold;
			}
		}
		.idx {
			>div{
				width: calc((100% - 90px) / 4);
				border-bottom: 1px solid #ccc;
				.hd{
					position: relative;
					background: $base;
					position: relative;
					height: 70px;
					i{
						@include centering;
						color: #fff;
						font-size: 1.6rem;
						white-space: nowrap;
						text-align: center;
					}
					&:before{
						content:"";
						width: 30px;
						height: 20px;
						position: absolute;
						top: 0;
						left: 0;
						@include transform(translate(-50%,-50%));
					}
					&.jp:before{
						@include bg-img(url(../img/fl_jp@2x.png)); 
					}
					&.cn:before{
						@include bg-img(url(../img/fl_cn@2x.png)); 
					}
				}
				ul{
					margin-top: 15px;
					line-height: 1.7;
					padding-bottom: 10px;
					padding-left: 1.2em;
					li{
						font-size: 1.4rem;
						white-space: nowrap;
						i{
							font-size: 1.2rem;
						}
					}
				}
			}
		}
	}
}//#organization
	
}//main#conmpany




/* !!------------------------------------ */
/* !! お問い合わせ */
main#contact{
	
	.lead{
		max-width: 800px;
		margin: 0 auto;
		margin-bottom: 50px;
		padding-bottom: 50px;
		border-bottom: 1px solid $txt;
		font-size: 1.6rem;
		line-height: 2.25;
		white-space: nowrap;
		.font-large & {
			white-space: normal;
		}
		i{
			color: $red;
		}
	}
	
	#form-wrap{
		margin-top: 0px;
		background: #eee;
		position: relative;
		padding-bottom: 100px;
		@include bg-img(url(../../contact/img/form-bg@2x.png),cover); //$url,$size,$position,$repeat
		h2{
			text-align: center;
			line-height: 1;
			font-size: 24px;
			margin-bottom: 50px;
		}
		
		#mailformpro{
			margin: 0 auto;
			position: relative;
			max-width: 1000px;
			background: #FFF !important;
			padding: 50px 100px;
			padding-top: 0;
		}
		
		
		.idx{
			>div{
				width: calc(50% - 15px);
				margin-bottom: 30px;
				p{
					line-height: 1;
					font-size: 16px;
					margin-bottom: 10px;
					span{
						color: $red;
					}
				}
				&.txtarea{
					width: 100% !important;
					textarea{
						height: 200px;
						width: 100%;
						display: block;
						border: 1px solid $txt;
					}
				}
			}
		}
		
		
		input[type="text"],
		input[type="email"],
		input[type="tel"],label{
			display: block;
			padding: 0;
			border: none;
			max-width: 100%;
			padding: 0 8px;
			font-size: 14px;
			height: 30px;
			width: 100% !important;
			border-radius: 0 !important;
			box-shadow: none !important;
			border: 1px solid $txt;
			margin: 0;
			&:focus{
				background: #ebfbff !important;
				outline: none;
			}
		}
		
		label{
			height: 70px;
			display: block;
			padding: 0 25px;
			width: calc(50% - 15px) !important;
			span{
				line-height: 1;
				display: block;
				font-size: 18px;
				top: 50%;position: relative;
				@include transform(translateY(-50%));
				padding: 0 03px;
				padding-left: 40px;
				&.checked:after{
					opacity: 1;
				}
				&:after{
					opacity: 0;
					content: "";
					display: block;
					width: 20px;
					border: 1px solid $txt;
					border-width: 0 0 1px 1px;
					height: 10px;
					position: absolute;
					top: -3px;left: 4px;
					
					@include transform(rotate(-45deg));					
				}
				input{
					appearance: none;
					display: block;
					width: 20px;
					height: 20px;
					border: 1px solid $txt;
					position: absolute;
					top: 0;left: 0;
				}
			}
		}
		
		.select{
			position: relative;
			padding-left: 166px;
			margin: 30px 0;
			.hide{
				display: none !important;
			}
			span{
				color: $red;
			}
			i{
				line-height: 40px;
				font-size: 18px;
				position: absolute;
				top: 0;left: 0;
			}
			.wrap{				
				height: 40px;
				&:after{
					content: "";
					@include anchor();//$color,$size,$Bw,$left,$right
					border-width: 0 0 1px 1px;
					margin-top: -3px;
									
				}
				select{
					font-size: 15px;
					appearance: none;
					width: 100%;
					height: 100%;
					display: block;
					max-width: 1000px;
					background: none;
					box-shadow: none;
					line-height: 30px;
					border: 1px solid $txt;
					margin: 0;
					border-radius: 0;
					&:focus{
						outline: none;
					}
				}
			}
		}
		
		textarea{
			padding:10px;
			border: none;
			max-width: 100%;
			height: 100px !important;
			width: 100% !important;
			font-size: 14px;
			border-radius: 0 !important;
			box-shadow: none !important;
			&:focus{
				background: #ebfbff !important;
				outline: none;
			}
		}
	
		
		.mfp_err{
			background: none;
			padding-left: 0;
			font-size: 12px;
			letter-spacing: .1em;
		}
		
		.note{
			text-align: center;
			line-height: 1;
			margin-bottom: 30px;
		}
		
		.btn-wrap{
			text-align: center;
		}
		
		.btn-wrap input,
		.mfp_element_button{
			border-radius: 0;
			text-shadow: none;
			appearance: none;
			display: inline-block;
			margin: 0 15px;
			background: $base;
			color: #fff;
			width: 250px;
			line-height: 50px;
			font-weight: bold;
			border: none;
			font-size: 16px;
			&#mfp_button_cancel{
				background: #000;
			}
			&[type=reset]{
				background: #000;
			}
		}
		
	}
	#mfp_phase_confirm{
		max-width: 1000px;
		margin: 0 auto;
		background: #fff;
		position: relative;
		//top: -100px;
	}
	#mfp_phase_confirm_inner{
		overflow: hidden;
		padding: 50px 100px;
		margin-top: -50px;
		&:empty{
			border: none;
			display: none;
		}
		h4{
			font-size: 24px;
			
			max-width: 1000px;
			margin: 0 auto;
			margin-bottom: 50px !important;
		}
		
		#mfp_confirm_table{
		
			border: 1px dashed #aaa;
			margin-bottom: 30px;
			border-collapse: collapse;
			width: 100%;
			td,th{
				font-size: 16px;
				padding: 10px;
				font-weight: normal;
				background: #FFF !important;
				border-style: dashed;
				border: 1px dashed #aaa;
			}
		}
		.mfp_buttons{
			padding: 0;
		}
	}
	
	#mfp_warning{
		@include transform(translateY(-50%));
		z-index: 2;
		background: none;
		font-size: 12px;
		border: none;
		color: $red;
		position: relative;
		top: 0;
		text-align: center;
		left:auto;
		margin: 0 auto !important;
		height: 50px;
		top: -50px;
		max-width: 800px;
	}
	
	
	//!! - thanks
	&.thanks{
		text-align: center;
		height:calc(100vh - 375px - 72px);
		min-height: 500px;
		position: relative;
		p.accept{
			border: 1px dashed #aaa;
			
			@include centering;
			display: inline-block;
			margin-top: 24px;
			padding: 50px;
			line-height: 1.8;
			white-space: nowrap;
			b{
				display: block;
				font-size: 1.2em;
				letter-spacing: .1em;
			}
		}
	}
	
}//main#contact



/* !!------------------------------------ */
/* !! よくある質問 */

main#faq{
	padding-bottom: 100px;
	min-height:calc(100vh - 375px - 72px);
	ul.filter{
		max-width: 1000px;
		margin: 0 auto;
		li{
			width: calc((100% - 100px) / 3);
			height: 50px;
			border: 1px solid $txt;
			font-size: 1.8rem;
			@include flex-centering;
			letter-spacing: .1em;
			cursor: pointer;
			&.fade{
				border: 1px dashed #bbb;
				color: #bbb;
			}
			&.current{
				border-width: 2px;
			}
		}
		
	}//ul.select
	
	dl{
		max-width: 1000px;
		margin: 0 auto;
		dt{
			margin-top: 50px;
			font-size: 2rem;
			font-weight: 600;
			line-height: 25px;
			padding-bottom: 15px;
			border-bottom: 2px dotted $txt;
			padding-left: 95px;
			position: relative;
			cursor: pointer;
			&:before{
				content:"";
				width: 80px;
				height: 25px;
				background: #eee;
				@include BBS;
				font-size: 14px;
				letter-spacing: .1em;
				@include flex-centering;
				color: $txt;
				position: absolute;
				top: 0;left: 0;
			}
			&:after{
				transition: 100ms;
				content: "";
				width: 30px;
				height: 30px;
				border-radius: 50%;
				position: absolute;
				right: 0;top: 0;
				background: $base;
				@include bg-img(url(../../faq/img/plus@2x.png),10px); //$url,$size,$position,$repeat
			}
			&.opend:after{
				@include bg-img(url(../../faq/img/minus@2x.png),10px 2px); //$url,$size,$position,$repeat
			}
		}
		
		dd{
			display: none;
			p{
				font-size: 1.4rem;
				line-height: 1.86;
				margin-top: .5em;
			}
			ul.flow{
				margin-top: 30px;
				li{
					margin-top: 30px;
					p{
						@include flex-centering;
						align-items: flex-end;
						font-size: 1.4rem;
						text-align: right;
						margin: 0;
						@include pfix(.88em);
						width: 400px;
						white-space: nowrap;
						b{
							font-size: 1.6rem;
							display: block;
							font-weight: normal;
						}
					}
				}
			}
		}
		&.merit dt:before{
			content: "MERIT";
			background: #00A2DD;
			color: #fff;
		}
		&.risk dt:before{
			content: "RISK";
			background: #ECE034;
		}
		&.method dt:before{
			content: "METHOD";
			background: #8EC21E;
			color: #fff;
		}
	}
	
	
}//main#faq



/* !!------------------------------------ */
/* !! サイトポリシー プライバシーポリシー*/
main.policy{
	padding-bottom: 100px;
	.lead{
		max-width: 1000px;
		margin: 0 auto;
		line-height: 1.88;
	}
	
	dl{
		max-width: 1000px;
		margin: 0 auto;
		dt{
			margin-top: 50px;
			font-size: 2rem;
			font-weight: 600;
			line-height: 25px;
			padding-bottom: 15px;
			border-bottom: 2px dotted $base;
			color: $base;
			position: relative;
		}
		
		dd{
			p{
				font-size: 1.4rem;
				line-height: 1.86;
				margin-top: .5em;
				&.note{
					@include note(1.2em);
					font-size: 1.2rem;
				}
				&.mention{
					font-size: 1.2rem;
				}
			}
			ul{
				li{
					@include note(1.6em);
					font-size: 1.4rem;
					line-height: 1.63;
				
				}
			}
		}
	}

}//main.policy



/* !!------------------------------------ */
/* !! ニュース&トピックス */

main#news-and-topics{
	
	min-height:calc(100vh - 375px - 72px);
	
}//main#news-and-topics


/* !! - - -  */
/* !! index */

ul.post-list.news-and-topics{
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 100px;
	display: block !important;
	&.no-pager{
		margin-bottom: 100px;
	}
	li{
		border-bottom: 1px solid $txt;
		margin-bottom: 0;
		position: relative;
		i{
			position: absolute;
			top: 15px;
			background: $base;
			width: 80px;
			height: 20px;
			@include BBS;
			line-height: 1;
			
			color: #fff;
			font-size: 1.4rem;
			letter-spacing: .1em;
			a{
				display: block;
				@include flex-centering;
				width:100%;
				height:100%;
			}
		}
		>a{
			font-size: 1.6rem;
			padding: 15px 0;
			padding-left: 100px;
			display: block;
			&:hover{
				background: #efefef;
			}
			&:after{
				content: "";
				@include anchor($txt,$right:0);//$color,$size,$Bw,$left,$right					
			}
		}
	}
}


/* !! ページャー */
.wp-pagenavi{
	margin: 100px auto;
	text-align: center;
	padding: 0 30*$px;
	position: relative;
	max-width: 1000px;
	color: #fff;
	font-size: 0;
	a,span{
		font-size: 16px !important;
		width: 40px;
		height: 40px;
		background:#959EC2 ;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		margin: 0 10px;
		@include BBS;
		line-height: 40px;
		&.current{
			background: $base;
			color: #fff;
		}
	}
	.pages{
		display: none;
	}
	.previouspostslink{
		position: relative;
		display: inline-block;
		top: 0;left: 0;
		font-size: 0;
		width: 40px;height: 40px;
		background: none;
		display: inline-block;
		transform: rotate(180deg);
		margin-right: 20px;
		&:after{
			content: "";
			margin-top: -2px;
			@include anchor($base,15px,3px,0,0);//$color,$size,$Bw,$left,$right
		}
	}
	.nextpostslink{
		top: 0;right: 0;
		font-size: 0;
		position: relative;
		background: none;
		width: 40px;height: 40px;
		display: inline-flex;
		margin-left: 20px;
		&:after{
			content: "";
			@include anchor($base,15px,3px,0,0);//$color,$size,$Bw,$left,$right
			margin-top: -2px;
		}
	}
	.last,.first{
		display: none;
	}
}


/* !! - - -  */
/* !! single */

#news-and-topics.single{
	
	article{
		max-width: 1000px;
		margin: 0 auto;
		margin-top: 80px;
		font-size: 1.6rem;
		line-height: 1.88;
		h1.ttl{
			line-height: 1;
			font-size: 2rem;
			position: relative;
			padding-left: 100px;
			margin-bottom: 50px;
			i{
				position: absolute;
				top: 0px;left: 0;
				background: $base;
				width: 80px;
				height: 20px;
				@include BBS;
				line-height: 1;
				color: #fff;
				font-size: 1.4rem;
				letter-spacing: .1em;
				a{
					display: block;
					@include flex-centering;
					width:100%;
					height:100%;
					color: #fff;
					font-size: 16px;
				}
			}
		}
		h2{
			margin: 2em 0px;
			margin-bottom: 1em;
		}
		p{
			font-size: 1.6rem;
			line-height: 1.88;
		}
	}
	
	
	//!! - 前後の記事
	.prev-next{
		margin-top: 50px;
		background: $gray-lite2;
		padding: 50px 0;
		ul{
			max-width: 1000px;
			margin: 0 auto;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: space-between;
		}
		li{
			width: calc(50% - 25px);
			@include flex-centering;
			justify-content: flex-start;
			align-items: flex-start;
			padding-bottom: 50px;
			position: relative;
			a.anchor{
				position: absolute;
				//background: #eee;
				width: 40px;
				height: 40px;
				top: 50%;
				left: -20px;
				@include transform(translate(-100%,-50%) rotate(180deg));
				&:after{
					content: "";
					@include anchor($base,16px,3px,$right:12px);//$color,$size,$Bw,$left,$right					
					margin-top: -2px;
				}
				&.next{
					top: 50%;
					left: auto;
					right: -20px;
					@include transform(translate(100%,-50%));
				}
			}
			i{
				background: $base;
				width: 80px;
				height: 20px;
				display: block;
				@include BBS;
				line-height: 1;
				color: #fff;
				font-size: 1.4rem;
				letter-spacing: .1em;
				a{
					display: block;
					@include flex-centering;
					width:100%;
					height:100%;
					color: #fff;
					font-size: 16px;
				}
			}
			
			p.ttl{
				margin: 20px 0;
				align-self: flex-start;
				font-weight: bold;
				font-size: 1.8rem;
			}

			.btn-more{
				height: 30px;
				line-height: 28px;
				width: 150px;
				margin: 0 auto;
				display: block;
				margin-right: 0;
				position: absolute;
				bottom: 0;right: 0;
				margin-bottom: 0;
			}
		}
	}//.prev-next
	
	
}//#news-and-topics.single

	a.backToIndex{
		width: 300px;
		height: 50px;
		font-size: 1.6rem;
		background: $base;
		color: #fff;
		display: block;
		@include flex-centering;
		margin: 50px auto;
		letter-spacing: .2em;
	}


/* !!------------------------------------ */
/* !! セミナー＆イベント */
main#seminar-and-event{
	
	width: auto;
	padding-left: 0;
	height: auto !important;
	min-height:calc(100vh - 375px - 80px);
	min-width: 320px;

}//main#seminar-and-event

ul.post-list.seminar-and-event{
	&.no-pager{
		margin-bottom: 100px;
	}
	max-width: 1000px;
	margin: 0 auto;
	li{
		width: calc((100% - 100px) / 3);
		margin-bottom: 50px;
		position: relative;
		padding-bottom: 30px;
		&:nth-last-of-type(-n + 3){
			margin-bottom: 0;
		}
		.img{
			height: 200px;
			background: #efefef;
			position: relative;
			&:before{
				content:"";
				@include BBS;
				text-align: center;
				font-size: 20px;
				letter-spacing: .1em;
				color: #ccc;
				width: 100%;
				display: block;
				position: absolute;
				top: 50%;
				@include transform(translateY(-50%));
			}
			&.seminar:before{
				content:"SEMINAR\A&\A EVENT";
			}
			&.news:before{
				content: "NEWS & TOPICS"
			}
			a{
				display: block;
				position: relative;
				z-index: 1;
			}
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		.txt{
			position: relative;
			z-index: 1;
			padding: 20px;
			margin-left: 30px;
			background: #fff;
			margin-top: -50px;
			i{
				background: $base;
				width: 80px;
				height: 20px;
				display: block;
				@include BBS;
				line-height: 1;
				color: #fff;
				font-size: 1.4rem;
				letter-spacing: .1em;
				position: absolute;
				top: 0;right: 0;
				@include transform(translateY(-50%));
				a{
					display: block;
					@include flex-centering;
					width:100%;
					height:100%;
					color: #fff;
					font-size: 16px;
				}
			}
			p{
				font-size: 1.8rem;
				font-weight: 600;
				line-height: 1.67;
				@include pfix(.67em);
			}
		}
		.btn-more{
			display: block;
			position: absolute;
			bottom: 0;right: 0;
			z-index: 1;
			line-height: 30px;
			height: 30px;
			width: 150px;
		}
	}
	
}//ul.post-list.seminar-and-event

/* !! - - -  */
/* !! single */

main#seminar-and-event.single{
	
	
	
	.mv{
		max-width: 1000px;
		margin: 0 auto;
		margin-top: 100px;
		img{
			margin: 0 auto;
			display: block;
			height: auto;
			max-width: 100%;
			margin-bottom: 50px;
		}
	}
	
	article{
		padding: 0;
		max-width: 1000px;
		margin: 0 auto;
		.content{
			p{
				font-size: 1.6rem;
				line-height: 2;
			}
		}
	}
	h1.ttl{
		font-size: 2rem;
		position: relative;
		padding-left: 0px;
		margin-bottom: 20*$px;
		line-height: 1.5;
	}	
	i.cat{
		background: $base;
		width: 80px;
		height: 20px;
		display: block;
		@include BBS;
		line-height: 1;
		color: #fff;
		font-size: 1.4rem;
		letter-spacing: .1em;
		margin-bottom: 10px;
		a{
			display: block;
			@include flex-centering;
			width:100%;
			height:100%;
			color: #fff;
			font-size: 1.4rem;
		}
	}
	
	section.outline{
		margin-top: 80px;
		background: $gray-lite2;
		h2{
			line-height: 1;
			top: 0;left: 0;
			font-size: 2.4rem;
			letter-spacing: .1em;
			//@include transform(translateY(-	100%));
			margin-bottom: 10px;
		}
		.inner{
			padding: 50px 0;
			max-width: 1000px;
			margin: 0 auto;
		}
		
		ul.outline{
			>li{
				background: #fff;
				margin-bottom: 5px;
				position: relative;
				padding: 10px;
				padding-left: 110px;
				ul{
					list-style: disc;
					margin-left: 1.8em;
					font-size: 1.4rem;
				}
				i{
					position: absolute;
					left: 0;top: 0;
					//height: 100%;
					background: $base;
					width: 100px;
					height: 100%;
					@include flex-centering;
					font-size: 1.6rem;
					color: #fff;
				}
			}
		}//ul.outline
		
		.btn-entry{
			height: 60px;
			border-radius: 30px;
			max-width: 400px;
			display: block;
			background: #fdd33c;
			@include flex-centering;
			line-height: 1;
			font-size: 1.6rem;
			color: #222;
			font-weight: bold;
			letter-spacing: .1em;
			margin: 50px auto;margin-bottom: 0;
		}
		
	}//.outline

	
}